import { useMemo } from "react";
import { parseISO, differenceInDays } from "date-fns";

export const useIsNewProject = (published_at: string): boolean => {
  // published_at が現在時刻から2週間以内の場合は true を返す
  const isNew = useMemo(() => {
    const now = new Date();
    const publishedAt = parseISO(published_at);
    const diffDays = differenceInDays(now, publishedAt);

    return diffDays <= 14; // 2週間以内
  }, [published_at]);

  return isNew;
};
