import { FC } from "react";
import { theme } from "tailwind.config";
import IconLikeFill from "@/assets/imgs/svg/icon-like-fill.svg";
import { useSignInRequiredAction } from "@/features/user_authentication";
import { RedirectReason } from "@/domain/values/RedirectReason";
import { getReasonText } from "@/utils/getReasonText";

type Props = {
  hasLiked: boolean;
  onClick: VoidFunction;
  size?: number;
};

const LikeIconBtn: FC<Props> = ({
  onClick,
  hasLiked,
  size = 20,
}): JSX.Element => {
  const { executeAction, SignInModalComponent } = useSignInRequiredAction({
    action: onClick,
    message: getReasonText(RedirectReason.Like),
  });

  return (
    <>
      <button onClick={executeAction}>
        <IconLikeFill
          width={size}
          height={size}
          fill={hasLiked ? theme.colors.like : theme.colors.gray[300]}
        />
      </button>
      {SignInModalComponent}
    </>
  );
};

export default LikeIconBtn;
