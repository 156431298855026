import MenuList from "@/components/MenuList";
import { PUBLIC_URL } from "@/config/env";
import DialogDeleteProject from "@/features/edit_project/components/DialogDeleteProject";
import useDisclosure from "@/hooks/useDisclosure";
import useSnackbar from "@/hooks/useSnackbar";
import copy from "copy-to-clipboard";
import { useRouter } from "next/router";
import { FC } from "react";

interface Props {
  projectId: number;
  onDeleted: VoidFunction;
  isPublished: boolean;
}

const ProjectItemMenu: FC<Props> = ({ projectId, onDeleted, isPublished }) => {
  const router = useRouter();
  const { show } = useSnackbar();
  const { isOpen, openHandler, closeHandler } = useDisclosure();

  const normalItems = [
    {
      label: "編集",
      key: "edit",
      onClick: () => {
        router.push(`/projects/${projectId}/edit`);
      },
    },
  ];

  if (isPublished) {
    normalItems.push({
      label: "URLをコピー",
      key: "copy",
      onClick: () => {
        copy(`${PUBLIC_URL}/projects/${projectId}`);
        show("URLをコピーしました", "success");
      },
    });
  }

  return (
    <>
      <MenuList
        items={[
          normalItems,
          [
            {
              label: <span className="text-danger">削除</span>,
              key: "delete",
              onClick: openHandler,
            },
          ],
        ]}
      />

      <DialogDeleteProject
        projectId={projectId}
        isOpen={isOpen}
        close={closeHandler}
        onDeleted={onDeleted}
      />
    </>
  );
};
export default ProjectItemMenu;
